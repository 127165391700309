import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1065.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
  

  <path d="M2215 12579 c-16 -22 -42 -57 -58 -77 -41 -55 -87 -122 -87 -127 0
-2 -11 -20 -24 -40 -47 -69 -137 -267 -174 -385 -44 -139 -91 -510 -64 -510 5
0 12 -42 15 -92 10 -160 54 -310 149 -503 65 -134 98 -194 114 -210 5 -5 30
-37 55 -70 79 -106 103 -127 146 -123 30 2 41 9 56 36 19 32 19 34 1 67 -10
19 -32 50 -49 69 -72 83 -123 163 -182 281 -108 216 -153 400 -153 620 0 116
22 302 40 335 5 10 10 30 10 44 0 14 4 26 10 26 5 0 12 17 15 38 6 32 34 101
68 167 66 124 87 161 133 230 29 44 64 92 78 107 14 15 26 34 26 42 0 8 5 18
10 21 16 10 12 34 -11 66 -16 23 -28 29 -57 29 -31 0 -42 -7 -67 -41z"/>
<path d="M4403 12600 c-29 -27 -36 -53 -21 -81 12 -22 96 -138 108 -149 12
-10 70 -111 70 -120 0 -6 4 -10 9 -10 5 0 14 -12 20 -27 5 -16 19 -44 30 -63
11 -19 27 -53 36 -75 9 -22 20 -47 24 -55 4 -8 18 -49 30 -90 12 -41 27 -86
32 -100 18 -52 33 -189 33 -315 0 -160 -10 -227 -58 -388 -24 -82 -121 -300
-146 -329 -20 -25 -60 -89 -60 -98 0 -5 -6 -13 -13 -17 -8 -4 -18 -15 -23 -25
-5 -9 -23 -31 -39 -50 -17 -18 -38 -46 -48 -61 -17 -27 -17 -31 -3 -62 28 -59
85 -63 138 -7 86 90 258 362 258 409 0 7 4 13 8 13 5 0 14 15 20 33 6 17 23
66 37 107 36 99 54 168 61 238 3 31 10 66 15 77 12 25 6 304 -9 391 -12 77
-34 166 -43 179 -4 6 -10 26 -14 45 -4 19 -11 40 -15 45 -4 6 -11 21 -15 35
-21 68 -119 266 -164 328 -11 17 -21 33 -21 36 0 9 -90 127 -137 181 -27 31
-71 33 -100 5z"/>
<path d="M2538 12263 c-13 -16 -35 -45 -47 -65 -13 -21 -26 -38 -29 -38 -4 0
-16 -18 -26 -40 -11 -22 -23 -40 -28 -40 -4 0 -8 -8 -8 -19 0 -10 -7 -24 -15
-31 -8 -7 -15 -18 -15 -25 0 -6 -10 -32 -23 -56 -39 -77 -80 -233 -92 -349
-11 -112 21 -311 69 -435 29 -74 75 -173 88 -190 5 -5 32 -46 61 -90 28 -44
61 -88 72 -97 27 -23 87 -23 108 0 31 34 21 78 -30 145 -27 34 -51 64 -55 65
-5 2 -8 12 -8 23 0 10 -4 19 -9 19 -4 0 -21 28 -36 63 -15 34 -37 82 -48 107
-44 99 -61 220 -53 390 6 119 21 186 54 247 7 12 12 27 12 33 0 18 63 140 72
140 4 0 8 5 8 12 0 11 69 108 97 136 13 13 18 41 13 72 -7 55 -89 69 -132 23z"/>
<path d="M4084 12276 c-32 -24 -26 -93 14 -135 57 -63 162 -248 162 -287 0 -8
4 -14 8 -14 10 0 28 -67 43 -155 13 -72 4 -354 -11 -369 -5 -5 -10 -20 -10
-33 0 -42 -99 -247 -152 -315 -21 -26 -38 -52 -38 -58 0 -5 -5 -10 -10 -10 -6
0 -15 -12 -21 -27 -32 -85 62 -140 127 -74 35 35 39 40 88 116 21 33 47 72 57
87 11 15 19 32 19 38 0 6 9 28 20 50 52 101 92 279 96 425 4 122 -3 167 -59
360 -15 55 -110 244 -136 272 -9 10 -27 34 -40 53 -46 67 -73 90 -106 90 -17
0 -40 -6 -51 -14z"/>
<path d="M2883 12015 c-13 -9 -23 -20 -23 -26 0 -5 -11 -22 -25 -38 -34 -37
-82 -129 -110 -211 -56 -159 -46 -370 22 -498 14 -26 34 -64 45 -84 11 -21 23
-38 27 -38 3 0 17 -18 31 -39 14 -24 34 -42 50 -46 14 -3 32 -8 41 -11 21 -6
69 40 69 67 0 12 -11 37 -25 55 -14 18 -25 35 -25 39 0 3 -8 15 -19 26 -30 32
-72 126 -83 187 -6 31 -13 65 -17 76 -6 17 8 141 24 215 10 43 85 176 119 213
29 30 33 72 11 101 -19 26 -84 33 -112 12z"/>
<path d="M3742 12007 c-24 -26 -31 -87 -9 -87 13 -1 107 -143 107 -163 0 -7 4
-17 9 -22 18 -18 44 -130 49 -204 2 -52 -1 -91 -13 -130 -20 -66 -78 -179
-106 -209 -10 -12 -19 -25 -19 -30 0 -5 -9 -17 -20 -27 -26 -23 -25 -55 0 -83
28 -30 92 -30 121 -1 28 28 119 171 119 187 0 6 5 12 10 12 6 0 10 9 10 20 0
12 6 33 14 48 8 15 19 68 25 117 17 129 -6 267 -62 380 -45 93 -62 122 -89
155 -16 19 -33 41 -39 48 -17 21 -83 15 -107 -11z"/>
<path d="M3310 11739 c-127 -50 -185 -183 -128 -295 12 -24 25 -44 30 -44 11
0 10 -43 0 -56 -5 -5 -19 -38 -32 -74 -12 -36 -33 -88 -46 -117 -13 -28 -24
-58 -24 -65 0 -7 -7 -22 -15 -32 -8 -11 -15 -30 -15 -42 0 -13 -4 -25 -9 -29
-5 -3 -15 -22 -21 -43 -7 -20 -27 -73 -45 -117 -18 -44 -51 -125 -74 -180 -23
-55 -41 -103 -41 -107 0 -6 -70 -178 -100 -243 -5 -11 -12 -31 -16 -45 -3 -14
-14 -43 -24 -65 -10 -22 -32 -78 -50 -125 -37 -99 -65 -168 -91 -227 -11 -23
-19 -48 -19 -56 0 -8 -7 -20 -15 -27 -8 -7 -15 -21 -15 -31 0 -10 -11 -43 -25
-73 -14 -30 -25 -59 -25 -65 0 -6 -6 -25 -14 -43 -13 -29 -27 -63 -56 -133
-10 -26 -70 -174 -80 -200 -4 -11 -18 -45 -30 -75 -11 -30 -25 -64 -29 -75 -5
-11 -16 -40 -26 -65 -21 -56 -34 -86 -51 -125 -7 -16 -27 -68 -45 -115 -45
-120 -61 -150 -81 -150 -9 0 -20 -4 -23 -10 -3 -5 -15 -10 -26 -10 -12 0 -19
-7 -19 -20 0 -11 -6 -20 -14 -20 -7 0 -25 -11 -40 -25 -15 -14 -32 -25 -38
-25 -6 0 -48 -38 -94 -85 -47 -47 -89 -85 -94 -85 -6 0 -10 -4 -10 -8 0 -4
-18 -28 -40 -52 -22 -24 -40 -49 -40 -56 0 -6 -11 -20 -25 -30 -14 -10 -25
-24 -25 -31 0 -7 -11 -24 -25 -39 -14 -15 -25 -33 -25 -40 0 -8 -4 -14 -10
-14 -5 0 -10 -11 -10 -24 0 -13 -6 -26 -14 -29 -17 -7 -46 -63 -46 -89 0 -10
-7 -18 -15 -18 -10 0 -15 -10 -15 -29 0 -17 -5 -33 -10 -36 -5 -3 -10 -15 -10
-26 0 -10 -7 -19 -15 -19 -9 0 -15 -9 -15 -25 0 -14 -4 -25 -10 -25 -5 0 -10
-13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -22 -10 -50 0 -27 -4 -50 -10 -50
-5 0 -10 -18 -10 -39 0 -22 -4 -43 -10 -46 -5 -3 -10 -37 -10 -74 0 -49 -4
-72 -15 -81 -22 -18 -22 -361 0 -384 10 -10 15 -35 15 -80 0 -37 4 -66 10 -66
6 0 10 -22 10 -50 0 -27 5 -50 10 -50 6 0 10 -20 10 -45 0 -25 5 -45 10 -45 6
0 10 -16 10 -35 0 -19 5 -35 10 -35 6 0 10 -14 10 -30 0 -18 6 -33 15 -36 8
-4 15 -15 15 -25 0 -10 5 -19 10 -19 6 0 10 -13 10 -29 0 -17 4 -33 10 -36 9
-6 22 -31 51 -102 8 -18 17 -33 21 -33 5 0 8 -7 8 -15 0 -8 5 -24 10 -35 6
-11 15 -29 20 -40 6 -11 10 -26 10 -33 0 -8 11 -20 25 -27 14 -7 25 -21 25
-30 0 -10 11 -26 25 -36 14 -10 25 -26 25 -36 0 -9 14 -29 30 -45 17 -15 30
-34 30 -41 0 -7 11 -23 25 -36 14 -13 25 -28 25 -35 0 -6 36 -47 80 -91 45
-45 78 -85 75 -90 -5 -8 54 -60 68 -60 9 0 127 -123 127 -132 0 -5 6 -8 13 -8
7 0 18 -9 24 -19 7 -11 23 -21 35 -23 13 -2 27 -13 33 -26 5 -12 15 -22 22
-22 7 0 13 -4 13 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 7 -10 15 -10
8 0 15 -5 15 -11 0 -15 26 -39 42 -39 7 0 27 -14 45 -30 18 -17 38 -30 46 -30
8 0 20 -7 27 -15 7 -8 19 -15 26 -15 8 0 14 -4 14 -9 0 -5 8 -8 17 -7 10 0 19
-7 21 -16 3 -16 -5 -18 -57 -18 -48 0 -61 3 -61 15 0 11 -13 15 -54 15 -30 0
-58 5 -61 10 -3 6 -33 10 -65 10 -32 0 -62 5 -65 10 -8 14 -385 14 -385 0 0
-6 -33 -10 -80 -10 -47 0 -80 -4 -80 -10 0 -5 -25 -10 -55 -10 -40 0 -57 -4
-61 -15 -5 -11 -21 -15 -60 -15 -30 0 -54 -4 -54 -10 0 -5 -13 -10 -29 -10
-17 0 -33 -4 -36 -10 -3 -5 -17 -10 -30 -10 -13 0 -27 -5 -30 -10 -9 -15 -73
-40 -101 -40 -13 0 -24 -3 -24 -7 0 -9 -48 -33 -65 -33 -5 0 -19 -11 -29 -25
-10 -14 -27 -25 -37 -25 -10 0 -26 -11 -35 -25 -9 -14 -21 -25 -28 -25 -6 0
-17 -9 -24 -20 -7 -12 -16 -18 -21 -16 -5 3 -57 -44 -117 -104 -60 -61 -114
-110 -121 -110 -7 0 -13 -9 -13 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -21
0 -11 -4 -17 -10 -14 -5 3 -10 0 -10 -7 0 -8 -14 -26 -32 -41 -30 -27 -58 -69
-58 -88 0 -4 -13 -23 -30 -41 -16 -18 -30 -40 -30 -50 0 -10 -7 -18 -15 -18
-8 0 -15 -9 -15 -19 0 -11 -4 -23 -10 -26 -5 -3 -10 -15 -10 -26 0 -10 -4 -19
-10 -19 -5 0 -10 -6 -10 -13 0 -7 -10 -33 -22 -58 -12 -24 -21 -52 -19 -62 1
-9 -5 -20 -13 -23 -9 -3 -16 -17 -16 -30 0 -13 -9 -39 -20 -59 -11 -20 -20
-52 -20 -71 0 -19 -7 -42 -16 -51 -8 -9 -14 -25 -13 -37 2 -11 -2 -31 -9 -43
-7 -12 -12 -42 -12 -67 0 -25 -4 -46 -10 -46 -6 0 -10 -40 -10 -99 0 -56 -4
-103 -10 -106 -13 -8 -13 -272 0 -280 5 -3 10 -37 10 -74 0 -37 5 -72 11 -78
7 -7 13 -31 14 -55 6 -81 23 -172 34 -176 6 -2 11 -12 11 -23 0 -11 5 -28 10
-39 6 -11 14 -29 20 -40 5 -11 10 -36 10 -55 0 -19 4 -35 10 -35 5 0 12 -12
16 -27 3 -16 9 -37 14 -48 5 -11 14 -35 22 -53 7 -17 16 -32 20 -32 5 0 8 -8
9 -17 0 -10 9 -31 20 -48 10 -16 22 -37 25 -45 25 -60 38 -82 54 -92 10 -6 16
-17 13 -24 -2 -6 0 -15 6 -19 6 -3 11 -15 11 -25 0 -11 9 -24 20 -30 11 -6 27
-26 35 -45 7 -19 19 -35 24 -35 6 0 11 -7 11 -16 0 -9 20 -36 45 -60 25 -23
45 -48 45 -55 0 -7 20 -32 45 -56 25 -23 45 -48 45 -55 0 -7 36 -48 80 -92 44
-43 80 -81 80 -84 0 -8 50 -52 61 -52 5 0 9 -4 9 -10 0 -5 6 -10 13 -10 14 0
117 -97 117 -111 0 -5 6 -9 13 -9 7 0 19 -9 27 -20 8 -11 19 -20 26 -20 6 0
19 -9 29 -20 10 -11 26 -20 35 -20 10 0 25 -12 35 -26 10 -15 22 -23 31 -20
15 6 54 -26 54 -44 0 -5 9 -10 20 -10 10 0 27 -8 37 -18 10 -10 30 -24 45 -31
16 -8 28 -18 28 -23 0 -4 6 -8 13 -8 20 0 117 -51 117 -61 0 -5 7 -9 15 -9 8
0 23 -4 33 -9 9 -5 34 -18 55 -28 66 -31 77 -37 77 -45 0 -5 9 -8 19 -8 11 0
23 -4 26 -10 3 -5 19 -10 36 -10 16 0 29 -4 29 -10 0 -5 9 -10 19 -10 11 0 23
-5 26 -10 3 -5 19 -10 35 -10 15 0 30 -7 34 -15 3 -8 15 -15 26 -15 11 0 20
-4 20 -10 0 -5 13 -10 29 -10 17 0 33 -4 36 -10 3 -5 19 -10 36 -10 19 0 29
-5 29 -15 0 -11 12 -15 44 -15 25 0 48 -4 51 -10 3 -5 19 -10 36 -10 16 0 29
-4 29 -10 0 -5 18 -10 39 -10 22 0 43 -5 46 -10 3 -6 30 -10 60 -10 30 0 57
-4 60 -10 3 -5 28 -10 55 -10 33 0 50 -5 54 -15 4 -11 23 -15 81 -15 43 0 75
-4 75 -10 0 -6 48 -10 125 -10 104 0 125 -2 125 -15 0 -22 378 -22 386 0 5 12
29 15 130 15 76 0 124 4 124 10 0 6 26 10 59 10 43 0 60 4 65 15 4 11 22 15
70 15 36 0 68 5 71 10 3 6 28 10 55 10 27 0 52 5 55 10 3 6 23 10 45 10 24 0
40 5 43 15 4 8 26 17 56 21 28 4 52 12 55 20 3 8 19 14 36 14 16 0 30 5 30 10
0 6 14 10 30 10 32 0 70 17 83 38 4 6 17 12 27 12 11 0 20 5 20 10 0 6 13 10
29 10 17 0 33 4 36 10 3 5 14 10 23 10 10 0 23 9 30 20 7 11 21 20 32 20 11 0
20 4 20 9 0 12 58 41 82 41 10 0 24 11 30 25 6 14 19 25 28 25 9 0 25 9 35 20
10 11 25 20 33 20 8 0 30 16 49 35 19 19 40 35 48 35 7 0 23 14 35 30 12 17
28 30 36 30 8 0 19 11 24 25 5 14 17 25 28 25 10 0 51 34 91 75 40 41 77 75
82 75 5 0 9 4 9 9 0 5 14 22 30 38 17 15 37 40 45 56 8 15 22 41 31 58 9 16
25 35 35 42 10 6 19 17 19 24 0 12 17 36 46 67 6 6 19 30 29 54 10 23 22 42
27 42 4 0 8 6 8 13 0 7 9 23 20 35 11 12 20 28 20 37 0 9 7 18 15 21 8 4 15
14 15 24 0 11 5 22 10 25 6 3 10 15 10 26 0 10 5 19 10 19 6 0 10 10 10 22 0
12 3 18 7 15 7 -8 33 58 33 84 0 9 7 22 15 29 8 7 15 22 15 34 0 12 9 46 20
76 11 30 20 63 20 72 0 10 5 18 10 18 6 0 10 13 10 29 0 17 4 33 10 36 5 3 10
29 10 56 0 35 4 51 15 55 11 5 15 21 15 59 0 30 5 57 10 60 6 3 10 32 10 64 0
31 5 62 10 67 19 19 0 578 -21 598 -5 6 -9 36 -9 67 0 41 -4 58 -15 63 -11 4
-15 21 -15 61 0 30 -4 55 -10 55 -5 0 -10 14 -10 30 0 17 -4 30 -8 30 -12 0
-32 47 -32 75 0 13 -6 30 -13 37 -8 6 -12 21 -9 33 2 12 -4 27 -12 34 -9 8
-16 26 -16 41 0 15 -7 33 -15 40 -17 14 -45 67 -45 87 0 7 -4 13 -10 13 -5 0
-10 9 -10 20 0 11 -7 20 -15 20 -8 0 -15 9 -15 20 0 12 -9 30 -20 42 -11 12
-20 30 -20 40 0 10 -4 18 -9 18 -5 0 -14 14 -21 30 -7 17 -19 30 -26 30 -8 0
-14 9 -14 19 0 11 -16 36 -35 57 -19 20 -35 42 -35 47 0 6 -25 34 -55 63 -30
29 -55 57 -55 63 0 6 -4 11 -9 11 -10 0 -151 137 -151 148 0 4 -24 23 -53 42
-28 19 -70 52 -91 73 -22 20 -44 37 -50 37 -6 0 -20 11 -31 24 -12 14 -48 39
-80 57 -32 18 -69 43 -81 56 -12 12 -29 23 -38 23 -9 0 -16 5 -16 10 0 6 -8
10 -19 10 -10 0 -27 11 -37 25 -10 14 -27 25 -36 25 -10 0 -18 5 -18 10 0 6
-9 10 -19 10 -11 0 -23 5 -26 10 -3 6 -15 10 -26 10 -10 0 -19 5 -19 10 0 6
-11 10 -25 10 -14 0 -25 5 -25 11 0 17 -27 39 -48 39 -11 0 -22 7 -26 15 -3 8
-16 15 -30 15 -14 0 -28 4 -31 10 -3 5 -15 10 -26 10 -10 0 -19 5 -19 10 0 6
-16 10 -35 10 -19 0 -35 4 -35 8 0 9 -59 32 -83 32 -9 0 -17 5 -17 10 0 6 -10
10 -21 10 -12 0 -27 7 -33 16 -7 8 -31 18 -55 21 -23 3 -45 12 -48 19 -3 8
-16 14 -29 14 -13 0 -32 4 -42 9 -9 5 -35 14 -57 21 -22 7 -48 19 -57 28 -16
14 -20 13 -38 -3 -19 -18 -20 -31 -20 -482 l0 -463 25 0 c14 0 25 -4 25 -10 0
-5 16 -10 35 -10 19 0 35 -3 35 -7 0 -10 49 -33 67 -33 8 0 16 -7 19 -15 4 -8
12 -15 20 -15 8 0 14 -4 14 -10 0 -5 17 -10 38 -10 32 0 41 -5 51 -26 7 -16
17 -24 26 -21 7 3 19 -1 25 -8 7 -8 16 -13 20 -10 4 3 13 -2 20 -10 7 -8 23
-15 35 -15 30 0 105 -40 105 -56 0 -6 18 -14 40 -18 22 -3 40 -11 40 -16 0 -6
7 -10 16 -10 9 0 33 -16 54 -36 37 -35 57 -49 110 -76 14 -7 31 -21 38 -30 7
-10 20 -18 28 -18 17 0 204 -183 204 -200 0 -5 5 -10 11 -10 12 0 88 -80 89
-94 0 -4 18 -26 40 -47 22 -22 40 -48 40 -58 0 -10 7 -21 15 -25 8 -3 15 -11
15 -19 0 -7 11 -24 25 -37 13 -12 22 -26 20 -31 -3 -4 -1 -10 5 -14 5 -3 10
-15 10 -25 0 -10 6 -20 12 -22 7 -3 11 -18 10 -36 -1 -18 2 -32 7 -32 13 0 41
-58 41 -86 0 -13 5 -26 10 -29 6 -3 10 -23 10 -45 0 -23 5 -40 14 -43 19 -7
26 -88 32 -346 3 -134 1 -208 -6 -215 -5 -5 -10 -31 -10 -57 0 -29 -6 -53 -15
-63 -8 -8 -15 -28 -15 -45 0 -16 -4 -33 -10 -36 -6 -3 -10 -21 -10 -40 0 -19
-4 -37 -10 -40 -5 -3 -10 -17 -10 -31 0 -14 -7 -27 -15 -30 -8 -4 -15 -17 -15
-30 0 -13 -4 -32 -9 -42 -5 -9 -16 -32 -23 -50 -7 -17 -16 -32 -20 -32 -5 0
-8 -9 -8 -19 0 -11 -5 -23 -10 -26 -6 -3 -10 -14 -10 -25 0 -10 -7 -20 -15
-24 -8 -3 -15 -15 -15 -26 0 -11 -4 -20 -8 -20 -5 0 -15 -12 -23 -27 -8 -14
-26 -39 -41 -55 -23 -25 -32 -28 -92 -28 -37 0 -66 -4 -66 -10 0 -6 -35 -10
-84 -10 -65 0 -85 -3 -90 -15 -8 -22 -681 -23 -693 -1 -5 9 -34 16 -83 20
-164 12 -201 18 -207 32 -3 9 -21 14 -49 14 l-44 0 0 418 c0 413 0 419 -21
438 -21 19 -21 18 -44 -9 -13 -15 -31 -27 -39 -27 -8 0 -18 -4 -21 -10 -3 -5
-15 -10 -26 -10 -10 0 -19 -4 -19 -9 0 -12 -57 -41 -80 -41 -21 0 -70 -23 -70
-32 0 -5 -20 -8 -44 -8 -25 0 -48 -4 -51 -10 -8 -14 -402 -14 -410 0 -9 14
-47 12 -67 -4 -16 -12 -18 -34 -18 -199 0 -107 -4 -188 -10 -192 -5 -3 -8 -21
-6 -41 1 -19 -3 -39 -11 -45 -7 -6 -10 -15 -7 -20 3 -5 -2 -12 -10 -15 -9 -3
-16 -14 -16 -24 0 -11 -4 -22 -10 -25 -5 -3 -10 -13 -10 -21 0 -16 -172 -194
-188 -194 -16 0 -132 -63 -132 -72 0 -4 -18 -8 -39 -8 -22 0 -43 -5 -46 -10
-3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 8 -10 18 -10 10 0 23 -5 30
-12 9 -9 17 -9 34 0 18 10 325 12 1308 10 1139 -3 1285 -5 1288 -18 4 -22
-152 -180 -177 -180 -16 0 -21 -6 -21 -25 0 -18 -5 -25 -20 -25 -11 0 -20 -4
-20 -8 0 -5 -21 -21 -47 -35 -27 -14 -54 -35 -61 -46 -7 -12 -19 -21 -28 -21
-9 0 -25 -11 -36 -25 -11 -14 -28 -25 -39 -25 -10 0 -19 -4 -19 -10 0 -5 -8
-10 -19 -10 -10 0 -24 -7 -31 -15 -16 -19 -68 -45 -92 -45 -10 0 -18 -7 -18
-15 0 -8 -6 -15 -13 -15 -21 0 -77 -30 -77 -41 0 -5 -11 -9 -25 -9 -13 0 -40
-9 -60 -20 -20 -11 -42 -20 -50 -20 -8 0 -15 -4 -15 -10 0 -5 -13 -10 -29 -10
-17 0 -33 -4 -36 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -7 -41 -15 -7 -8 -27
-15 -46 -15 -18 0 -36 -4 -39 -10 -3 -5 -26 -10 -51 -10 -24 0 -44 -4 -44 -10
0 -5 -18 -10 -39 -10 -23 0 -41 -5 -44 -13 -6 -15 -145 -29 -320 -32 -57 -2
-103 -7 -110 -14 -15 -15 -189 -15 -205 1 -7 7 -54 12 -129 13 -171 2 -301 15
-314 31 -7 8 -31 14 -60 14 -26 0 -51 4 -54 10 -3 5 -17 10 -30 10 -13 0 -27
5 -30 10 -3 6 -26 10 -51 10 -30 0 -46 5 -50 15 -4 10 -20 15 -45 15 -21 0
-39 5 -39 10 0 6 -9 10 -19 10 -11 0 -23 5 -26 10 -3 6 -17 10 -30 10 -13 0
-27 5 -30 10 -3 6 -19 10 -35 10 -16 0 -32 4 -35 10 -3 5 -14 10 -25 10 -10 0
-20 7 -24 15 -3 9 -18 15 -36 15 -16 0 -30 5 -30 10 0 6 -9 10 -19 10 -10 0
-21 7 -25 15 -3 8 -12 15 -21 15 -8 0 -23 4 -33 9 -9 5 -39 19 -67 32 -27 13
-59 32 -69 41 -11 10 -28 18 -38 18 -10 0 -21 7 -24 15 -4 8 -12 15 -18 15 -7
0 -23 10 -36 23 -14 12 -27 22 -30 22 -3 1 -15 6 -26 13 -12 6 -24 8 -28 5 -3
-3 -6 -1 -6 5 0 7 -5 12 -11 12 -6 0 -20 11 -31 25 -11 14 -28 25 -39 25 -10
0 -19 5 -19 10 0 13 -63 69 -95 86 -13 7 -52 42 -87 79 -35 36 -78 73 -95 82
-18 8 -33 22 -33 31 0 8 -25 40 -55 69 -30 30 -55 58 -55 62 0 5 -7 11 -15 15
-8 3 -15 12 -15 21 0 8 -7 15 -15 15 -9 0 -19 8 -22 18 -4 9 -15 26 -25 36
-10 11 -18 28 -18 38 0 10 -7 18 -16 18 -8 0 -12 4 -9 10 3 6 1 10 -4 10 -6 0
-11 8 -11 18 -1 21 -23 52 -39 52 -6 0 -11 9 -11 19 0 11 -11 28 -25 39 -14
11 -25 27 -25 35 0 9 -5 19 -10 22 -5 3 -10 15 -10 26 0 10 -4 19 -10 19 -5 0
-10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 16 0 8 -7 22 -15 31 -8 8 -13
19 -9 24 3 5 -2 12 -10 15 -9 4 -16 18 -16 34 0 16 -5 32 -10 35 -6 3 -10 15
-10 26 0 10 -4 19 -10 19 -5 0 -10 16 -10 35 0 24 -5 35 -15 35 -10 0 -15 10
-15 29 0 17 -4 33 -10 36 -5 3 -10 26 -10 51 0 24 -4 44 -10 44 -5 0 -10 22
-10 49 0 28 -4 53 -10 56 -5 3 -10 39 -10 80 0 41 -5 77 -10 80 -13 8 -14 385
-1 385 5 0 12 30 15 68 11 134 18 182 27 182 5 0 9 18 9 39 0 22 4 43 10 46 5
3 10 14 10 25 0 10 7 20 15 24 9 3 15 18 15 35 0 16 4 33 10 36 5 3 10 17 10
30 0 13 5 27 10 30 6 3 10 19 10 35 0 15 6 30 13 33 7 2 24 27 37 53 14 27 28
51 33 52 4 2 7 13 7 25 0 12 11 31 25 41 14 10 25 24 25 30 0 6 11 24 25 40
14 16 25 33 25 37 0 5 14 22 30 37 17 16 30 34 30 40 0 6 18 30 40 53 22 23
40 46 40 51 0 4 5 8 10 8 11 0 60 45 60 55 0 9 64 64 76 65 6 0 19 9 29 20 10
11 24 20 32 20 8 0 23 9 35 20 12 11 27 20 34 20 6 0 14 7 18 15 3 8 15 15 26
15 11 0 20 5 20 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 19 10 35 10 15 0 30 6
33 14 3 8 29 17 66 21 33 4 65 12 70 19 6 7 27 11 48 9 21 -1 44 3 53 11 24
22 88 28 324 33 144 2 223 0 230 -7 5 -5 23 -10 38 -10 16 0 40 -7 53 -16 15
-10 34 -14 49 -10 14 3 28 0 35 -8 6 -7 33 -16 59 -20 27 -3 57 -13 68 -21 10
-8 27 -15 37 -15 9 0 17 -4 17 -10 0 -5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5
13 -10 21 -10 14 0 20 -4 83 -60 19 -16 39 -30 45 -30 15 0 96 -77 96 -90 0
-6 4 -10 9 -10 5 0 11 -9 14 -20 3 -11 12 -20 21 -20 9 0 16 -4 16 -10 0 -5 5
-10 11 -10 14 0 39 -25 39 -39 0 -6 7 -11 15 -11 8 0 15 -9 15 -20 0 -11 5
-20 10 -20 6 0 10 -8 10 -18 0 -10 9 -28 20 -40 11 -12 20 -28 20 -37 0 -8 5
-15 10 -15 6 0 10 -7 10 -15 0 -8 6 -15 13 -15 16 0 37 -46 37 -81 0 -15 3
-29 8 -31 14 -6 43 -68 37 -79 -4 -5 0 -18 9 -28 13 -14 18 -48 23 -137 4 -84
10 -121 19 -127 20 -12 20 -183 0 -190 -13 -5 -23 -87 -25 -203 -1 -34 -25
-99 -41 -109 -5 -3 -10 -15 -10 -26 0 -10 -4 -19 -10 -19 -5 0 -10 -13 -10
-29 0 -17 -4 -33 -10 -36 -5 -3 -10 -14 -10 -25 0 -10 -7 -20 -15 -24 -8 -3
-15 -11 -15 -19 0 -7 -12 -24 -26 -38 -14 -13 -32 -38 -41 -56 -8 -18 -22 -33
-29 -33 -8 0 -14 -7 -14 -15 0 -8 -8 -15 -18 -15 -11 0 -27 -10 -38 -21 -10
-12 -29 -25 -41 -30 -35 -13 -27 -29 12 -29 22 0 38 -6 41 -15 5 -12 27 -15
114 -15 83 0 110 3 120 15 8 10 31 15 71 15 32 0 61 5 64 10 3 6 24 10 45 10
21 0 42 5 45 10 3 5 21 10 40 10 21 0 35 6 39 16 3 8 10 13 15 10 5 -4 18 5
29 19 11 14 25 25 31 25 6 0 19 4 29 9 9 5 25 12 35 15 10 3 16 10 12 16 -3 5
14 32 39 58 26 27 46 56 46 65 0 9 4 19 10 22 6 3 10 15 10 26 0 10 4 19 9 19
13 0 41 59 41 87 0 12 5 23 10 23 6 0 10 13 10 29 0 17 5 33 10 36 5 3 10 28
10 54 0 26 5 52 10 57 14 14 7 378 -8 454 -7 30 -14 73 -18 95 -4 22 -9 42
-13 45 -14 12 -31 52 -31 75 0 14 -7 28 -15 31 -13 5 -15 188 -15 1560 l0
1554 44 0 c29 0 46 5 49 14 5 11 18 13 60 9 35 -4 57 -2 61 6 5 7 49 11 127
11 97 0 121 3 125 15 5 13 41 15 235 15 197 0 229 -2 229 -15 0 -12 20 -15
120 -15 80 0 120 -4 120 -11 0 -7 24 -9 71 -6 51 3 74 0 84 -10 8 -7 29 -13
49 -13 19 0 38 -4 41 -10 3 -5 19 -10 35 -10 16 0 32 -4 35 -10 3 -5 28 -10
55 -10 27 0 52 -4 55 -10 3 -5 22 -10 42 -10 36 0 91 -14 125 -31 10 -5 22 -9
28 -9 13 0 55 -42 56 -55 0 -5 8 -24 17 -42 9 -18 17 -40 17 -51 0 -10 7 -27
15 -38 8 -10 15 -29 15 -41 0 -12 5 -25 10 -28 6 -3 10 -15 10 -26 0 -10 4
-27 9 -37 5 -9 12 -25 15 -34 3 -10 12 -18 21 -18 13 0 15 21 15 145 l0 145
33 0 c21 0 41 -8 52 -20 10 -12 25 -18 32 -16 8 3 16 -1 19 -9 4 -8 15 -15 25
-15 10 0 19 -4 19 -10 0 -5 11 -10 25 -10 16 0 25 -6 25 -15 0 -8 8 -15 18
-15 11 0 27 -9 37 -20 10 -11 26 -20 35 -20 10 0 21 -7 24 -16 3 -9 12 -14 20
-11 8 3 25 -7 39 -23 14 -16 42 -36 61 -45 20 -10 36 -22 36 -27 0 -13 51 -58
65 -58 6 0 20 -10 33 -22 12 -13 60 -62 107 -109 47 -47 85 -90 85 -95 1 -13
66 -84 77 -84 6 0 20 -20 33 -45 13 -25 29 -50 37 -56 7 -6 13 -17 13 -23 0
-6 9 -18 20 -26 11 -8 20 -19 20 -26 0 -17 29 -70 45 -84 17 -14 45 -68 45
-87 0 -7 5 -13 10 -13 6 0 10 -9 10 -20 0 -11 4 -20 8 -20 9 0 42 -70 42 -88
0 -7 5 -12 10 -12 6 0 10 -11 10 -23 0 -26 25 -83 41 -92 5 -4 9 -15 9 -26 0
-11 9 -39 20 -61 11 -23 20 -54 20 -69 0 -15 5 -31 10 -34 6 -3 10 -19 10 -36
0 -16 5 -29 10 -29 6 0 10 -14 10 -32 0 -31 14 -87 31 -120 4 -9 9 -36 10 -60
1 -23 8 -52 16 -63 7 -11 13 -45 13 -76 0 -35 5 -59 12 -61 7 -3 11 -27 9 -71
-1 -42 2 -67 8 -67 7 0 11 -30 11 -79 0 -44 5 -83 10 -86 14 -8 14 -812 0
-820 -6 -3 -10 -39 -10 -80 0 -41 -5 -77 -10 -80 -6 -4 -9 -30 -8 -59 2 -31
-2 -59 -9 -68 -7 -8 -13 -40 -14 -71 -1 -32 -7 -58 -14 -60 -6 -3 -12 -29 -14
-58 -1 -30 -6 -55 -12 -57 -5 -2 -9 -17 -9 -32 0 -16 -5 -32 -10 -35 -6 -3
-10 -19 -10 -35 0 -16 -5 -32 -11 -36 -6 -3 -9 -15 -6 -25 3 -11 -1 -26 -9
-33 -8 -8 -14 -24 -14 -35 0 -11 -9 -36 -20 -56 -11 -20 -20 -47 -20 -61 0
-13 -7 -27 -15 -30 -8 -3 -20 -24 -26 -45 -7 -21 -16 -39 -21 -39 -4 0 -8 -4
-8 -10 0 -18 -60 -138 -75 -150 -8 -7 -15 -19 -15 -27 0 -7 -7 -16 -15 -19 -8
-4 -15 -13 -15 -20 0 -8 -9 -24 -20 -36 -11 -12 -20 -27 -20 -34 0 -21 -25
-58 -68 -100 -23 -23 -42 -51 -42 -63 0 -11 -4 -21 -9 -21 -5 0 -59 -49 -119
-110 -61 -60 -114 -110 -119 -110 -4 0 -25 -16 -47 -35 -21 -19 -42 -33 -46
-30 -15 9 -60 -47 -60 -76 0 -15 -4 -30 -9 -34 -16 -9 -41 -66 -41 -92 0 -12
-4 -23 -10 -23 -5 0 -10 -11 -10 -25 0 -13 -9 -40 -20 -60 -11 -20 -20 -42
-20 -50 0 -8 -7 -15 -15 -15 -9 0 -15 -9 -15 -25 0 -14 -4 -25 -10 -25 -14 0
-12 -26 3 -38 9 -8 24 -8 52 0 22 5 57 12 77 15 21 3 42 11 49 19 6 8 29 14
50 14 21 0 41 5 44 10 3 6 19 10 36 10 16 0 29 4 29 10 0 5 16 12 35 16 19 3
35 10 35 15 0 5 7 9 15 9 8 0 30 9 50 20 20 11 46 20 60 20 13 0 27 5 30 10 3
6 14 10 25 10 10 0 20 7 24 15 3 8 15 15 26 15 11 0 29 4 40 10 11 5 29 14 40
20 11 5 27 10 35 10 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 11 10
25 10 14 0 25 6 25 13 0 14 72 57 96 57 8 0 19 9 26 20 7 11 18 20 25 20 7 0
13 7 13 15 0 8 6 15 13 15 28 0 74 31 132 89 33 34 65 61 72 61 6 0 44 33 85
73 40 41 78 76 83 78 13 5 65 75 65 87 0 5 25 33 55 61 30 29 55 58 55 64 0 7
14 26 30 44 17 18 30 36 30 41 0 5 6 15 14 22 8 6 13 17 10 24 -3 8 2 16 10
19 9 4 16 15 16 25 0 10 9 27 20 37 11 10 20 26 20 37 0 10 7 18 15 18 8 0 15
9 15 19 0 11 4 23 10 26 6 3 10 15 10 26 0 10 5 19 10 19 6 0 10 6 10 13 0 17
49 115 61 123 5 3 9 18 9 35 0 16 5 29 10 29 6 0 10 11 10 25 0 16 6 25 15 25
8 0 15 9 15 20 0 11 5 20 10 20 6 0 10 18 10 40 0 22 9 55 20 75 11 20 20 51
20 70 0 19 5 35 10 35 6 0 10 13 10 29 0 17 5 33 10 36 6 3 10 23 10 44 0 20
5 42 11 48 12 12 26 100 35 228 3 44 10 84 15 89 5 6 9 49 9 97 0 48 5 91 10
94 6 4 10 90 10 225 0 135 -4 221 -10 225 -6 3 -10 50 -10 106 0 59 -4 99 -10
99 -6 0 -10 33 -10 78 0 45 -5 83 -11 89 -7 7 -13 33 -14 58 -2 26 -8 52 -14
58 -6 6 -11 35 -11 64 0 29 -4 53 -10 53 -5 0 -10 9 -10 19 0 11 -4 23 -10 26
-5 3 -10 24 -10 46 0 21 -4 39 -10 39 -5 0 -10 18 -10 39 0 22 -4 43 -10 46
-5 3 -10 19 -10 36 0 16 -4 29 -10 29 -5 0 -10 9 -10 20 0 11 -7 23 -15 26 -9
4 -15 19 -15 40 0 19 -4 34 -10 34 -5 0 -10 9 -10 19 0 10 -7 21 -15 25 -9 3
-15 18 -15 36 0 16 -4 30 -10 30 -5 0 -10 10 -10 23 0 12 -9 29 -20 37 -11 8
-20 22 -20 31 0 9 -7 20 -16 23 -8 3 -12 11 -8 17 4 7 1 17 -7 23 -8 6 -20 25
-28 41 -7 17 -17 38 -22 47 -5 10 -9 23 -9 30 0 7 -9 19 -20 26 -11 7 -20 18
-20 25 0 8 -11 24 -25 37 -14 13 -25 31 -25 39 -1 20 -24 51 -39 51 -6 0 -11
7 -11 15 0 8 -18 33 -40 56 -22 23 -40 48 -40 55 0 7 -16 25 -35 40 -19 14
-35 29 -35 33 0 16 -172 180 -183 175 -7 -2 -19 7 -27 21 -7 14 -17 25 -22 25
-5 0 -22 16 -39 35 -17 19 -35 32 -40 29 -5 -3 -12 2 -15 10 -3 9 -11 16 -16
16 -6 0 -18 9 -28 20 -9 10 -28 24 -41 30 -46 21 -70 38 -85 59 -8 11 -23 21
-33 21 -10 0 -30 11 -45 25 -15 14 -31 25 -35 25 -4 0 -16 9 -26 20 -10 11
-23 20 -28 20 -18 0 -117 52 -117 61 0 5 -9 9 -19 9 -11 0 -23 5 -26 10 -3 6
-15 10 -26 10 -11 0 -22 7 -25 15 -4 8 -14 15 -24 15 -11 0 -22 5 -25 10 -3 6
-15 10 -26 10 -10 0 -29 5 -42 12 -47 24 -57 28 -72 28 -8 0 -23 4 -33 9 -9 5
-43 17 -74 26 -32 9 -58 21 -58 26 0 5 -11 9 -25 9 -14 0 -25 5 -25 10 0 6
-18 10 -40 10 -29 0 -40 4 -40 15 0 11 -11 15 -40 15 -22 0 -40 5 -40 10 0 6
-22 10 -49 10 -28 0 -53 5 -56 10 -3 6 -24 10 -46 10 -21 0 -39 5 -39 10 0 6
-20 10 -45 10 l-45 0 -2 803 c-3 681 -5 802 -17 802 -8 0 -15 -9 -17 -20 -2
-11 -8 -21 -13 -23 -6 -2 -11 -12 -11 -22 0 -10 -4 -22 -10 -25 -5 -3 -10 -19
-10 -35 0 -15 -7 -30 -15 -34 -8 -3 -15 -13 -15 -23 0 -10 -9 -31 -19 -47 -11
-17 -18 -33 -15 -37 2 -5 -4 -13 -15 -18 -10 -6 -22 -26 -25 -45 -8 -44 -206
-246 -241 -246 -12 0 -28 -7 -35 -15 -10 -12 -40 -15 -146 -15 -112 0 -134 2
-134 15 0 11 -12 15 -48 15 -47 0 -102 18 -102 33 0 4 -6 7 -13 7 -8 0 -28 7
-46 16 -32 16 -59 58 -81 129 -12 39 -59 161 -85 220 -34 78 -58 137 -89 220
-55 146 -96 247 -118 295 -11 25 -24 54 -28 65 -4 11 -17 47 -29 80 -13 33
-38 98 -57 145 -19 47 -34 89 -34 94 0 4 -11 31 -23 59 -13 29 -34 79 -47 112
-13 33 -32 80 -43 105 -10 25 -22 54 -27 65 -4 11 -12 31 -18 45 -17 43 -74
184 -82 205 -35 92 -44 115 -57 150 -8 22 -21 54 -28 70 -7 17 -25 62 -40 100
-15 39 -33 84 -40 100 -7 17 -31 80 -54 140 -57 149 -80 205 -97 238 -8 16
-14 35 -14 42 0 7 -11 36 -25 63 -31 61 -31 71 -3 119 33 57 31 154 -5 207
-59 85 -159 126 -237 95z m92 -451 c9 -18 21 -49 28 -68 7 -19 21 -54 31 -77
11 -24 19 -46 19 -51 0 -4 9 -25 21 -46 39 -73 34 -78 -157 -176 -94 -48 -176
-85 -182 -81 -7 4 6 46 34 116 24 61 44 116 44 123 0 6 4 12 9 12 5 0 13 14
16 32 4 18 11 39 15 48 5 8 23 51 41 95 48 118 55 124 81 73z m194 -485 c3
-10 12 -31 19 -48 40 -85 65 -149 65 -166 0 -10 4 -20 9 -24 5 -3 15 -22 21
-43 7 -20 18 -51 26 -69 20 -45 29 -93 16 -93 -5 0 -27 9 -49 20 -21 11 -85
40 -143 65 -243 107 -361 166 -357 178 3 9 234 131 287 151 8 3 22 10 30 14
66 38 69 38 76 15z m-478 -322 c2 -6 11 -11 19 -11 8 0 32 -9 52 -19 20 -11
56 -28 81 -39 25 -11 84 -38 132 -61 48 -22 99 -43 113 -47 14 -3 25 -10 25
-15 0 -5 5 -9 12 -9 16 0 168 -73 168 -80 0 -6 -149 -77 -250 -119 -14 -6 -32
-15 -40 -20 -8 -5 -31 -15 -50 -23 -19 -9 -55 -25 -80 -36 -25 -12 -76 -35
-115 -52 -97 -43 -236 -106 -320 -145 -98 -45 -115 -50 -115 -31 0 9 11 39 25
68 14 28 25 56 25 60 0 5 11 33 24 61 13 29 27 63 30 77 4 14 18 50 30 80 13
30 31 73 39 95 34 89 71 181 78 190 4 6 10 26 14 45 4 19 10 39 12 44 7 11 87
0 91 -13z m756 -397 c8 -3 20 -22 26 -42 6 -21 18 -50 25 -67 7 -16 25 -59 40
-95 14 -36 30 -74 35 -85 5 -11 26 -63 45 -115 73 -191 87 -225 95 -245 4 -11
20 -51 35 -90 15 -38 34 -83 41 -100 7 -16 15 -45 19 -62 4 -18 11 -33 16 -33
5 0 9 -7 9 -15 0 -8 -6 -15 -14 -15 -18 0 -174 48 -200 62 -10 5 -30 12 -45
14 -14 3 -55 16 -91 30 -95 38 -128 50 -195 69 -33 10 -69 22 -80 27 -27 12
-245 88 -270 95 -11 2 -29 9 -40 14 -11 5 -67 24 -125 43 -151 50 -321 111
-352 126 -29 13 -37 30 -16 30 6 0 34 11 62 24 28 13 123 56 211 95 88 39 178
80 200 90 127 60 177 81 189 81 8 0 16 3 18 8 4 8 91 52 104 52 5 0 53 22 107
49 53 27 105 52 115 54 9 3 18 5 19 6 1 0 8 -2 17 -5z m-1198 -589 c16 -8 36
-15 46 -15 14 0 138 -43 203 -70 11 -5 29 -11 40 -13 11 -3 85 -29 165 -57 80
-29 157 -54 173 -57 15 -3 30 -9 33 -14 3 -5 15 -9 28 -9 12 0 27 -5 33 -11 6
-6 26 -14 44 -19 38 -9 263 -86 294 -100 34 -15 138 -51 173 -60 18 -4 38 -12
45 -18 7 -5 26 -12 42 -16 17 -4 53 -15 80 -26 28 -10 82 -28 120 -40 39 -12
84 -28 102 -36 17 -8 35 -14 40 -14 4 0 14 -15 22 -32 7 -18 19 -47 27 -65 8
-17 14 -35 14 -41 0 -5 7 -23 15 -38 8 -16 15 -37 15 -46 0 -21 -20 -34 -30
-19 -4 7 -19 13 -34 15 -15 1 -32 8 -38 14 -7 7 -20 12 -30 12 -10 0 -18 5
-18 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -13 10 -28 10 -32 0 -92 27
-92 41 0 5 -18 9 -39 9 -22 0 -43 5 -46 10 -3 6 -19 10 -34 10 -16 0 -34 7
-42 16 -7 8 -21 13 -30 11 -10 -3 -23 1 -29 8 -5 7 -38 15 -73 19 -126 14
-142 17 -145 26 -2 6 -48 10 -102 10 -55 0 -102 4 -105 10 -4 6 -85 10 -211
10 -129 0 -204 -4 -204 -10 0 -6 -36 -10 -89 -10 -50 0 -91 -4 -93 -10 -1 -5
-21 -12 -43 -14 -122 -15 -146 -20 -155 -32 -7 -8 -24 -11 -45 -7 -26 4 -36 2
-41 -11 -3 -9 -14 -16 -24 -16 -11 0 -22 -4 -25 -10 -3 -5 -19 -10 -36 -10
-19 0 -29 -5 -29 -15 0 -9 -9 -15 -25 -15 -14 0 -34 -4 -45 -10 -11 -5 -29
-14 -40 -20 -30 -15 -34 5 -10 54 23 48 86 207 127 321 14 39 30 72 34 73 5 2
9 10 9 17 0 8 7 31 16 52 9 21 20 47 25 58 4 11 20 49 34 85 15 36 29 73 32
83 6 21 4 20 39 2z m854 -690 c0 -12 14 -15 64 -15 36 0 68 -4 71 -10 3 -5 28
-10 55 -10 27 0 52 -4 55 -10 3 -5 22 -10 41 -10 20 0 37 -6 40 -14 3 -9 29
-16 70 -21 35 -3 66 -11 69 -16 4 -5 14 -9 25 -9 10 0 20 -7 24 -15 3 -9 18
-15 36 -15 16 0 30 -4 30 -10 0 -5 13 -10 29 -10 20 0 34 -8 45 -25 9 -14 25
-25 36 -25 11 0 20 -4 20 -10 0 -5 11 -10 25 -10 14 0 25 -4 25 -8 0 -4 16
-14 37 -21 20 -8 46 -24 58 -37 12 -13 32 -24 44 -24 11 0 21 -4 21 -10 0 -5
5 -10 10 -10 6 0 23 -11 38 -25 15 -14 33 -25 40 -25 7 0 25 -13 39 -30 13
-16 33 -30 44 -30 14 0 19 -7 19 -25 0 -20 5 -25 25 -25 14 0 25 -5 25 -11 0
-17 27 -39 49 -39 14 0 21 -6 21 -18 0 -22 19 -42 39 -42 19 0 81 -59 81 -77
0 -7 6 -13 13 -13 18 -1 67 -47 67 -65 0 -8 18 -32 40 -54 22 -21 40 -48 40
-60 0 -11 4 -21 8 -21 15 0 92 -79 92 -94 0 -8 14 -27 30 -43 17 -15 30 -35
30 -43 0 -9 9 -22 21 -30 11 -8 27 -31 35 -50 9 -19 20 -42 24 -51 5 -9 6 -20
3 -23 -11 -11 -403 -7 -403 4 0 7 -180 10 -520 10 -454 0 -521 2 -526 15 -5
13 -78 15 -569 15 -488 0 -564 -2 -575 -15 -10 -13 -51 -15 -248 -15 l-236 0
22 -20 c12 -11 35 -20 52 -20 18 0 29 -4 25 -10 -4 -6 6 -10 24 -10 16 0 33
-4 36 -10 3 -5 15 -10 26 -10 11 0 22 -7 25 -15 4 -8 14 -15 23 -15 9 0 23 -9
31 -20 8 -11 20 -20 27 -20 7 0 13 -4 13 -10 0 -5 29 -38 65 -73 36 -35 65
-68 65 -72 0 -4 13 -34 30 -66 16 -32 31 -74 33 -94 2 -19 7 -38 10 -42 4 -3
7 -46 7 -95 0 -72 3 -88 15 -88 13 0 15 -20 15 -120 0 -98 -3 -122 -15 -126
-13 -5 -15 -42 -15 -250 0 -237 -1 -244 -20 -244 -11 0 -20 4 -20 9 0 5 -16
16 -35 25 -19 10 -53 35 -74 57 -22 21 -44 39 -50 39 -14 0 -161 146 -161 159
0 6 -20 32 -45 58 -24 26 -45 53 -45 59 0 6 -9 19 -20 29 -11 10 -20 26 -20
37 0 10 -6 18 -13 18 -13 0 -57 71 -57 91 0 6 -9 19 -20 31 -11 12 -20 32 -20
45 0 13 -7 26 -15 29 -8 4 -15 15 -15 25 0 10 -4 19 -10 19 -5 0 -10 13 -10
28 0 30 -24 87 -41 97 -5 4 -9 24 -9 45 0 21 -4 41 -9 44 -10 7 -19 60 -27
154 -3 34 -10 62 -15 62 -12 0 -12 423 0 436 5 5 12 34 15 64 9 96 18 145 27
155 5 5 9 19 9 31 0 12 7 27 15 34 8 7 15 21 15 31 0 11 5 19 10 19 6 0 10 9
10 19 0 11 5 23 10 26 6 3 10 18 10 32 0 15 10 33 25 45 14 11 25 25 25 31 0
7 14 26 30 44 17 18 30 38 30 46 0 8 17 30 38 48 20 18 37 35 37 38 1 17 28
51 40 51 8 0 36 23 62 51 26 27 62 57 78 65 61 30 85 45 85 55 0 5 9 9 19 9
11 0 23 5 26 10 3 6 15 10 26 10 10 0 19 6 19 13 0 7 21 19 48 26 26 7 56 16
67 19 11 3 24 5 28 4 4 -1 10 5 13 13 4 10 20 15 50 15 24 0 44 5 44 10 0 6
18 10 39 10 22 0 43 5 46 10 3 6 26 10 51 10 27 0 52 6 60 15 13 12 53 15 235
15 188 0 219 -2 219 -15z"/>
<path d="M6950 8280 l0 -760 189 0 188 0 7 64 c8 78 8 1401 0 1433 -6 23 -7
23 -195 23 l-189 0 0 -760z"/>
<path d="M7695 9021 l-100 -6 -5 -180 -5 -180 -72 -3 -73 -3 0 -139 0 -139 73
-3 72 -3 5 -325 5 -325 28 -50 c37 -66 63 -92 117 -120 78 -39 173 -50 305
-35 l115 13 0 155 0 154 -55 -7 c-71 -8 -119 7 -134 43 -8 18 -10 105 -9 262
l3 235 98 3 97 3 0 139 0 139 -97 3 -98 3 -3 188 -2 187 -83 -2 c-45 -1 -127
-4 -182 -7z"/>
<path d="M8685 8665 c-84 -14 -156 -39 -204 -70 -80 -50 -159 -156 -174 -232
-8 -41 -28 -37 289 -70 l51 -5 16 41 c23 58 65 81 145 81 73 0 105 -17 121
-62 13 -36 14 -88 3 -88 -4 0 -59 -7 -122 -15 -259 -32 -398 -95 -477 -213
-84 -127 -82 -280 4 -404 64 -94 148 -133 283 -133 132 0 222 39 290 125 17
22 36 40 41 40 5 0 9 -31 9 -70 l0 -70 190 0 190 0 -6 33 c-3 17 -9 39 -12 47
-3 8 -9 193 -12 410 -6 432 -7 441 -68 518 -62 78 -138 115 -282 138 -103 16
-182 16 -275 -1z m255 -672 c0 -83 -24 -161 -59 -197 -25 -25 -40 -30 -91 -34
-53 -4 -64 -2 -85 18 -65 61 -33 174 65 229 31 18 106 39 143 40 26 1 27 0 27
-56z"/>
<path d="M10075 8658 c-62 -20 -107 -56 -156 -122 l-29 -39 0 76 0 77 -180 0
-180 0 0 -565 0 -565 194 0 195 0 3 394 3 395 29 30 c25 26 37 31 76 31 35 0
52 -5 66 -21 18 -20 19 -44 22 -425 l3 -404 195 0 195 0 -3 453 c-4 396 -6
459 -22 505 -51 157 -237 239 -411 180z"/>
<path d="M5752 8183 l3 -468 27 -57 c55 -118 132 -163 278 -163 116 0 203 46
261 137 l34 53 3 -83 3 -82 179 0 180 0 0 565 0 565 -192 -2 -193 -3 -5 -380
c-4 -279 -8 -386 -18 -402 -31 -55 -113 -72 -152 -31 -19 21 -20 33 -20 420
l0 398 -196 0 -195 0 3 -467z"/>
<path d="M8899 3443 c-1 -38 -4 -120 -8 -183 l-6 -115 -70 -5 -70 -5 -3 -137
-3 -137 43 -5 c24 -3 57 -7 73 -8 l30 -3 6 -310 c4 -252 8 -318 21 -352 36
-91 75 -131 163 -165 53 -20 76 -23 195 -21 74 1 149 6 165 11 l30 8 3 151 3
151 -86 -2 c-75 -1 -88 2 -101 18 -11 16 -14 69 -14 270 l0 251 66 -3 c36 -2
81 -1 100 3 l34 6 0 134 c0 157 7 149 -124 143 l-76 -3 0 188 0 187 -185 0
-185 0 -1 -67z"/>
<path d="M8020 3156 c-214 -41 -342 -144 -384 -309 -8 -35 9 -42 124 -50 47
-3 115 -9 151 -13 l66 -7 16 41 c43 106 232 112 267 8 24 -74 18 -79 -107 -93
-314 -35 -467 -120 -534 -297 -17 -46 -20 -74 -17 -134 8 -162 107 -283 257
-313 69 -13 192 -7 238 13 56 24 128 78 152 113 13 19 25 34 27 32 2 -1 6 -27
9 -57 11 -98 -7 -90 197 -90 l180 0 -7 43 c-3 23 -9 224 -13 447 l-7 405 -26
55 c-54 114 -155 179 -319 205 -80 12 -209 13 -270 1z m248 -662 c4 -47 -15
-148 -35 -187 -26 -50 -133 -84 -184 -57 -27 15 -49 63 -49 108 0 77 74 143
190 168 30 7 60 11 65 10 6 -1 11 -20 13 -42z"/>
<path d="M10005 3155 c-146 -23 -214 -54 -291 -130 -58 -58 -126 -196 -104
-210 5 -3 37 -8 72 -11 35 -3 99 -10 143 -15 126 -14 132 -13 140 20 17 77
139 117 225 73 36 -18 47 -41 49 -104 1 -36 -1 -38 -31 -39 -18 0 -40 -2 -48
-3 -8 -2 -44 -6 -80 -10 -87 -10 -226 -44 -275 -69 -111 -55 -192 -143 -214
-231 -25 -101 -11 -228 32 -291 61 -89 116 -127 211 -145 164 -31 297 13 388
127 15 20 29 34 29 32 1 -2 6 -35 13 -74 l11 -70 184 -2 183 -2 -7 32 c-4 18
-10 210 -14 427 -5 280 -11 410 -21 448 -15 58 -85 154 -135 183 -44 26 -149
56 -238 68 -94 13 -117 13 -222 -4z m233 -656 c7 -64 -9 -157 -32 -191 -54
-81 -186 -91 -217 -17 -16 39 -7 120 17 152 29 39 178 102 219 93 6 -2 12 -18
13 -37z"/>
</g>
</svg>



        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}